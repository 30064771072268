import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"

import { API_URL } from "../../../utils/helpers"
import { getUser } from "../../../utils/auth"

import Alert from "../../Alert/index"

import Nav from "../../Nav/index"

import Input from "../../form/Input"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"

import { Container, Grid } from "./styled"

const Manage = ({ id }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const initRef = useRef(false)

  useEffect(() => {
    if (initRef.current) return
    initRef.current = true
    if (getUser()) {
      init(id, getUser().token)
    }
  })

  const init = async (id, token) => {
    const url = new URL(`${API_URL}/`)
    const params = { 
      admin: "1",
      f: "adminDonationInit",
      id, 
      token, 
      x: uuidv4() 
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setNames(json)
        setAlert({})
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminDonation&admin=1`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append("token", getUser().token || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setMsg({})
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <useRef>
          <Nav
            sections={[
              { name: "Admin Home", to: "/" },
              { name: "", to: "" },
              { name: "Donations", to: "/donations" },
              { name: "", to: "" },
              { name: `Donation ${id}`, to: "" },
            ]}
            title="Details / Manage Donor Contact / Manage Guesses"
          />

          <p className="no-top-margin">
            Use this section to view a submitted donation’s details or manage
            donor contact info / guesses in the database.
          </p>

          <Grid>
            <div>
              <h3>Donation Details</h3>

              <table>
                <tbody>
                  <tr>
                    <th className="right">Date:</th>
                    <td>{names.date}</td>
                  </tr>
                  <tr>
                    <th className="right">Order Number:</th>
                    <td>{names.id}</td>
                  </tr>
                  <tr>
                    <th className="right">Charity:</th>
                    <td>{names.charity}</td>
                  </tr>
                  <tr>
                    <th className="right">Solicted By:</th>
                    <td>{names.solicited}</td>
                  </tr>
                  <tr>
                    <th className="right">Amount:</th>
                    <td>{names.amount}</td>
                  </tr>
                  <tr>
                    <th className="right"># of Guesses:</th>
                    <td>{names.numGuesses}</td>
                  </tr>

                  <tr>
                    <th className="right">Guess(es):</th>
                    <td>{names.guesses}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3>Donor Info</h3>

              <table>
                <tbody>
                  <tr>
                    <th className="right">Name:</th>
                    <td>{`${names.fname} ${names.lname}`}</td>
                  </tr>
                  <tr>
                    <th className="right">Address:</th>
                    <td>
                      {`${names.address} ${names.address2}`}
                      <br />
                      {`${names.city}, ${names.st} ${names.zip} `}
                    </td>
                  </tr>
                  <tr>
                    <th className="right">Phone:</th>
                    <td>{names.phone}</td>
                  </tr>
                  <tr>
                    <th className="right">Email:</th>
                    <td>{names.email}</td>
                  </tr>
                  <tr>
                    <th className="right">Payment:</th>
                    <td>{names.payment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <form onSubmit={e => handleSubmit(e)}>
            <div className="one">
              <div>
                <Input
                  type="text"
                  label="Solicited By"
                  note=""
                  req={false}
                  name="solicited"
                  value={names.solicited}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <div className="two">
              <div>
                <Input
                  type="text"
                  label="First Name"
                  req={true}
                  name="fname"
                  value={names.fname}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Last Name"
                  req={true}
                  name="lname"
                  value={names.lname}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Email Address"
                  req={true}
                  name="email"
                  value={names.email}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Phone"
                  req={true}
                  name="phone"
                  value={names.phone}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Address"
                  req={true}
                  name="address"
                  value={names.address}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Address 2"
                  req={false}
                  name="address2"
                  value={names.address2}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>
            <div className="three">
              <div>
                <Input
                  type="text"
                  label="City"
                  req={true}
                  name="city"
                  value={names.city}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div className="small">
                <Input
                  type="text"
                  label="State"
                  req={true}
                  name="st"
                  value={names.st}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div className="small">
                <Input
                  type="text"
                  label="Zip"
                  req={true}
                  name="zip"
                  value={names.zip}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <div className={names.noGuesses ? "hidden" : "guesses"}>
              {[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
              ].map((num, index) => {
                const guessNum = `guess${num}`
                return index + 1 <= names.numGuesses ? (
                  <div key={index}>
                    <Input
                      type="text"
                      label={`Guess ${num}`}
                      req={true}
                      name={guessNum}
                      value={names[guessNum]}
                      update={handleUpdate}
                      errors={errors}
                    />
                  </div>
                ) : null
              })}
            </div>

            {msg.type && <Msg data={msg} />}

            {msg.type !== "working" && (
              <Submit label="Update Details / Guesses" />
            )}
          </form>
        </useRef>
      )}
    </Container>
  )
}

export default Manage
