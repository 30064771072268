import React from "react"
import { Router } from "@reach/router"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Manage from "../components/donation/Manage"

const Empty = () => <div></div>

const Donation = () => {
  return (
    <Layout>
      <Helmet />
      <Router>
        <Manage path="/donation/:id" />
        <Empty exact path="/donation" />
      </Router>
    </Layout>
  )
}

export default Donation
